<template>
  <Layout>
    <section class="csms-charge-point">

      <!-- Page Title Start -->
      <PageHeader :title="title" />
      <!-- Page Title End -->

      <!-- Summary Area Start -->
      <div class="row">
        <div class="col-xl-3">
          <SummaryCardwithSlot :value="1234560" :unit="'원'">
            <template v-slot:title>
              <span class="text-secondary">이번 달</span> <span class="fw-bold">총 매출액</span>
            </template>
            <template v-slot:icon>
              <i class="mdi mdi-calculator font-size-26" />
            </template>
          </SummaryCardwithSlot>
        </div>

        <div class="col-xl-3">
          <SummaryCardwithSlot :value="12345" :unit="'원'">
            <template v-slot:title>
              <span class="text-secondary">이번 달</span> <span class="fw-bold">수수료(VAT 포함)</span>
            </template>
            <template v-slot:icon>
              <i class="uil uil-receipt font-size-26" />
            </template>
          </SummaryCardwithSlot>
        </div>

        <div class="col-xl-3">
          <SummaryCardwithSlot :value="98000" :unit="'원'">
            <template v-slot:title>
              <span class="text-secondary">이번 달</span> <span class="fw-bold">정산 예정 금액</span>
            </template>
            <template v-slot:icon>
              <span class="badge rounded-pill badge-soft-danger font-size-12">정산 예정</span>
            </template>
          </SummaryCardwithSlot>
        </div>

        <div class="col-xl-3">
          <SummaryCardwithSlot :value="2000" :unit="'원'">
            <template v-slot:title>
              <span class="text-secondary">이번 달</span> <span class="fw-bold">정산 완료 금액</span>
            </template>
            <template v-slot:icon>
              <span class="badge rounded-pill badge-soft-primary font-size-12">정산 완료</span>
            </template>
          </SummaryCardwithSlot>
        </div>
      </div>
      <!-- Summary Area End -->

      <!-- Search Area Start -->
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">

              <div class="row">
                <!-- // Search Input -->
                <div class="col-xl-10">
                  <div class="row">
                    <div class="col-xl-2">
                      <label class="form-label" for="settlement-search-status">상태</label>
                      <multiselect id="settlement-search-status"
                                   v-model="searchForm.status"
                                   label="text"
                                   track-by="value"
                                   placeholder=""
                                   :options="statusOptions"
                                   :searchable="false" :allow-empty="false"
                      />
                    </div>
                    <div class="col-xl-4">
                      <b-form-group label-class="form-label"
                                    label="정산예정일">
                        <div class="row align-items-center">
                          <date-picker type="date" id="valid-to-date"
                                       v-model="searchForm.startDate" class="col"
                                       :show-time-panel="showTimePanel" value-type="format"
                                       @close="handleOpenChange">
                            <template v-slot:footer="{ emit }">
                              <div class="d-flex justify-content-between">
                                <button class="mx-btn mx-btn-text" @click="emit(new Date())">
                                  Today
                                </button>
                                <button class="mx-btn mx-btn-text" @click="toggleTimePanel">
                                  {{ showTimePanel ? 'Select Date' : 'Select Time' }}
                                </button>
                              </div>
                            </template>
                          </date-picker>
                          <span class="col-auto">~</span>
                          <date-picker type="date" id="valid-to-date"
                                       v-model="searchForm.endDate" class="col"
                                       :show-time-panel="showTimePanel" value-type="format"
                                       @close="handleOpenChange">
                            <template v-slot:footer="{ emit }">
                              <div class="d-flex justify-content-between">
                                <button class="mx-btn mx-btn-text" @click="emit(new Date())">
                                  Today
                                </button>
                                <button class="mx-btn mx-btn-text" @click="toggleTimePanel">
                                  {{ showTimePanel ? 'Select Date' : 'Select Time' }}
                                </button>
                              </div>
                            </template>
                          </date-picker>
                        </div>

                      </b-form-group>
                    </div>
                  </div>
                </div>
                <!-- // Search Button -->
                <div class="col-xl-2 align-content-end">
                  <div class="float-end">
                    <b-button variant="secondary" class="me-2" @click="reset">
                      <i class="uil uil-redo me-2"></i>
                      <span>{{ $t('btn.reset') }}</span>
                    </b-button>
                    <b-button variant="primary" @click="search">
                      <i class="uil uil-search me-2"></i>
                      <span>{{ $t('btn.search') }}</span>
                    </b-button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- Search Area End -->

      <!-- List Start -->
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">

              <div class="row mb-3 align-items-end">

                <!-- // List PerPage Count -->
                <div class="col-auto">
                  <b-form-select class="form-select me-4 py-1"
                                 v-model="pagination.perPage"
                                 :options="[{ value: 10, text: `10${$t('unit.case')}` }, { value: 25, text: `25${$t('unit.case')}` }, { value: 50, text: `50${$t('unit.case')}` }]"
                                 @change="getItems"/>
                </div>

                <!-- // Total Count -->
                <div class="col me-2">
                  <div class="float-end">
                    <total-count :total-count="pagination.totalCount" />
                  </div>
                </div>
              </div>

              <!-- // List -->
              <div class="table-responsive">
                <b-table class="text-nowrap align-middle" bordered hover
                         thead-class="align-middle"
                         :fields="fields" :items="items">

                  <template #cell(b)="data">
                    <span class="badge rounded-pill badge-soft-danger font-size-12">{{ '정산 예정' }}</span>
                  </template>

                </b-table>
              </div>

              <!-- // Pagination -->
              <div class="row mt-3">
                <div class="col-xl-12">
                  <b-pagination size="sm" class="mb-0" align="center"
                                :total-rows="pagination.totalCount"
                                :per-page="pagination.perPage"
                                v-model="pagination.currentPage"
                                @change="onPageChange" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- List End -->

    </section>
  </Layout>
</template>

<script>
import { http } from '@/auth-api';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import Layout from '@/views/layouts/Main.vue';
import PageHeader from '@/components/PageHeader.vue';
import TotalCount from '@/views/components/TotalCount.vue';
import SummaryCardwithSlot from "@/views/components/SummaryCardwithSlot.vue";
import Multiselect from "vue-multiselect";

export default {
  name: "SettlementList",
  components: { Multiselect, Layout, PageHeader, SummaryCardwithSlot, TotalCount },
  data() {
    return {
      title: 'manageSettlement',
      searchForm: {
        status: '',
        startDate: '',
        endDate: '',
      },
      statusOptions: [
        { text: '전체', value: '' },
        { text: '정산 예정', value: 'yet' },
        { text: '정산 완료', value: 'done' },
      ],
      showTimePanel: false,
      searchConditions: [],
      sortDirection: 'DESC',
      pagination: {
        currentPage: 1,
        totalCount : 0,
        perPage: 10
      },
      fields: [
        { key: 'a', label: '사업본부명', class: 'text-center' },
        { key: 'b', label: '정산상태', class: 'text-center' },
        { key: 'c', label: '총 매출액(A)', class: 'text-center' },
        { key: 'd', label: '수수료율(B)', class: 'text-center' },
        { key: 'e', label: '수수료( C=A*B )', class: 'text-center' },
        { key: 'f', label: '수수료 VAT(D)', class: 'text-center' },
        { key: 'g', label: '정산 예정 금액( A-C-D )', class: 'text-center' },
        { key: 'h', label: '기준 기간', class: 'text-center' },
        { key: 'i', label: '정산 예정일', class: 'text-center' },
        { key: 'j', label: '정산 완료일', class: 'text-center' },
        { key: 'k', label: '명세서', class: 'text-center' },
      ],
      items: null,
    }
  },
  onPageChange(val) {
    const vm = this;
    vm.pagination.currentPage = val;

    if (vm.pagination.totalCount > 1) {
      vm.getItems();
    }
  },
  mounted() {
    const vm = this;
    vm.getItems()
  },
  methods: {
    reset() {
      const vm = this;

      vm.searchForm = {
        status: '',
        startDate: '',
        endDate: '',
      };
      vm.searchConditions = [];
      vm.showTimePanel = false;

      vm.getItems();
    },
    search() {
      const vm = this;

      if (this.$_utils.isEmpty(vm.searchForm.moid)
        && this.$_utils.isEmpty(vm.searchForm.memberNo)) {
        Swal.fire({
          icon: 'warning',
          title: i18n.t('alert.searchConditions'),
          confirmButtonText: i18n.t('btn.close')
        });
      } else {
        vm.searchConditions = [];
        const searchFields = Object.keys(vm.searchForm);

        searchFields.forEach(field => {
          const value = vm.searchForm[field];
          if (!this.$_utils.isEmpty(value)) {
            vm.searchConditions.push({ searchKey: field, searchValue: value });
          }
        });

        vm.pagination.currentPage = 1;

        vm.getItems();
      }
    },
    getItems() {
      const vm = this;
      //TODO: 정산관리 API 개발 후 반영

      Swal.fire({
        icon: 'warning',
        title: '개발중인 화면',
        showConfirmButton: false,
        timer: 3000,
      });
      /*const _url = '';

      http.post(_url, {
        page: vm.pagination.currentPage,
        limit: vm.pagination.perPage,
        searchConditions: vm.searchConditions,
        sortCondition: { sortDirection: vm.sortDirection }
      }).then(res => {
        vm.items = res.data.items;
        vm.pagination.totalCount = res.data.totalCount;
      }).catch(err => {
        console.error('Payment Unit Price List Error :: ', err)
      });*/
      vm.items = [{
        a: '울트라 파트너스',
        b: 'yet',
        c: '6,130',
        d: '15%',
        e: '300',
        f: '30',
        g: '5,800',
        h: '2024-02-01~2024-02-29',
        i: '2024-03-05',
        j: '-',
      }];
    },
    handleOpenChange() {
      const vm = this;
      vm.showTimePanel = false;
    },
    toggleTimePanel() {
      const vm = this;
      vm.showTimePanel = !vm.showTimePanel;
    },
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>