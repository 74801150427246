<template>
  <div class="card">
    <div class="card-body">
      <div class="mb-2">
        <slot name="title" />
      </div>
      <div :class="`d-flex justify-content-${alignClass} align-items-center`">
        <div class="me-3">
          <slot name="icon" />
        </div>
        <div class="d-flex align-items-baseline">
          <span v-if="unit === '¥'" class="text-black-50 me-1">{{ unit }}</span>
          <span class="font-size-26 fw-bold" :style="{ color: valueColor }">{{ value.toLocaleString() }}</span>
          <span v-if="unit != '¥'" class="text-black-50 ms-1">{{ unit }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SummaryCardwithSlot",
  props: {
    value: Number,
    unit: String,
    alignClass: {
      type: String,
      default: 'end',    // 'between', 'end'
    },
    valueColor: { type: String, default: 'black' },
  },
}
</script>